<template>
  <v-row>
    <!-- Attachment -->
    <v-col cols="12">
      <div class="mb-2 font-weight-medium">
        <span> Attachment </span>
      </div>
      <v-card
        class="mt-2"
        outlined
        @dragenter.prevent
        @dragover.prevent="overlay = 0.4"
        @dragleave.prevent="overlay = 0.1"
        @drop.prevent="dropFile"
      >
        <v-row no-gutters>
          <v-overlay :color="overlay_color" absolute z-index="0" :opacity="overlay"></v-overlay>
          <input ref="file" type="file" class="d-none" accept=".pdf" @change="changeFile" />

          <v-col class="py-8">
            <template v-if="file && file.type">
              <v-row justify="center">
                <v-col cols="12" sm="8" md="6" lg="4">
                  <v-card outlined class="d-flex">
                    <v-col>
                      <v-row class="flex-nowrap" no-gutters justify="space-between" align="center">
                          <a
                            class="text-truncate"
                            :href="file.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            style="direction: rtl"
                          >
                            {{ file.url }}
                          </a>

                        <div class="action d-flex flex-nowrap">
                          <v-btn small class="mx-2 fab-white" fab @click="$refs.file.click()">
                            <v-icon dark>
                              ri-arrow-go-back-line
                            </v-icon>
                          </v-btn>
                          <v-btn small class="mx-2 fab-white" fab @click="$delete(data, 'file')">
                            <v-icon dark>
                              ri-delete-bin-7-line
                            </v-icon>
                          </v-btn>
                        </div>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <div class="mb-2 text-center">
                <v-icon x-large>ri-image-add-fill</v-icon>
              </div>
              <div class="mb-2 text-center font-weight-medium">
                Tarik dan letakan berkas di sini
              </div>
              <div class="mb-2 text-center">atau</div>
              <div class="text-center">
                <v-btn color="primary" @click="$refs.file.click()">
                  <span>Pilih Berkas</span>
                </v-btn>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!-- Deskripsi -->
    <v-col cols="12">
      <div class="mb-2 font-weight-medium">
        <span> Deskripsi </span>
      </div>
      <vue-editor
        v-model="data.description"
        :editorToolbar="customToolbar"
        class="overflow-y-auto"
        style="max-height: 400px"
        placeholder="Masukan deskripsi"
      ></vue-editor>
    </v-col>
  </v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";
import model from "../_mixins/model";

export default {
  components: { VueEditor },
  mixins: [model],
  data: () => ({
    overlay: 0.1,
    overlay_color: "secondary",
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["blockquote", "link"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ],
  }),
  computed: {
    types() {
      return ["Gambar / File PDF", "Link", "No Media"];
    },
    IsAttachment() {
      return this.data.type == this.types[0];
    },
    IsLink() {
      return this.data.type == this.types[1];
    },
    IsNoMed() {
      return this.data.type == this.types[2];
    },
    file() {
      if (this.data.file instanceof File) {
        const file = this.data.file;
        const type = ["application/pdf"].includes(file.type) ? "document" : "file";
        const url = URL.createObjectURL(file);
        return { url, type };
      } else {
        return this.data.file;
      }
    },
  },
  methods: {
    dropFile(event) {
      this.overlay = 0.1;
      this.$set(this.data, "file", event.dataTransfer.files[0]);
      this.$set(this.data.file, "src", URL.createObjectURL(this.data.file));
    },
    changeFile() {
      this.$set(this.data, "file", this.$refs.file.files[0]);
      this.$set(this.data.file, "src", URL.createObjectURL(this.data.file));
    },
  },
  created() {
    // console.log(this.data);
  },
};
</script>
